/* Import videojs. */
import videojs from 'video.js';
import 'videojs-contrib-dash';
import 'videojs-contrib-eme';
import dashjs from 'dashjs';

/* Import local plugins. */
import '../plugins/SafeUpdate';
import '../plugins/Playlist';
import '../plugins/Recommendation/Recommendation';
import { trackers } from '@rmm/error-tracker';

/* Import utility functions. */
import {
	add_query_parameter,
	analyticsPayloadDict,
	setAnalyticsPayloadUsingAnvatoResponse,
	isMobile,
	detectBrowser,
	addOneTimeEvents,
	updateAdRequest,
	addCustomTagParsers,
	ERROR_TRACKER_RENDER_GROUP,
	videoStartDispatcher,
	shapeOVPEvent,
	canRunAds,
} from '../util/helper';

/* Import initialization routines. */
import initialize_ui from './ui';
import initialize_drm from './drm';
import initialize_ads from './ads';
import initialize_comscore from './comscore';
import 'videojs-overlay/dist/videojs-overlay';
import 'videojs-overlay/dist/videojs-overlay.css';

/* Import playlist UI. */
import '../VideojsComponents/PlaylistInterface';
import '../VideojsComponents/PlaylistInterfaceButton';
import { initialize_nielsen } from './nielsen';

const { SimpleErrorTracker } = trackers;

window.dash = dashjs; // Needed to parse dash correctly.

/**
 * Optimizes the poster image by tacking in width and auto parameters which
 * will be intercepted and fulfilled by Fastly Image Optimization.
 * Not used now.
 */
// eslint-disable-next-line no-unused-vars
function optimize_poster_image(poster, player) {
	if (poster !== undefined) {
		// tack in width
		if (!!player && !poster.includes('width=')) {
			const width = player?.currentWidth && player.currentWidth();
			poster = add_query_parameter(poster, 'width', width);
		}
		// tack in auto
		if (!poster.includes('auto=webp')) {
			poster = add_query_parameter(poster, 'auto', 'webp');
		}
	}
	return poster;
}

function generate_videojs_info(info) {
	const { poster, url: src } = info;
	return {
		poster,
		sources: [
			{
				src,
				type: 'application/x-mpegURL',
			},
		],
	};
}

export function update_player(
	player,
	info,
	autoplay = false,
	show_title_overlay = false,
	overrided_title,
	load_info = {
		is_manually_triggered: false,
		recommendation_index: 0,
		load_method: 'recommendation',
	},
	player_id,
	player_name,
	_udl = {},
) {
	/* Early exit if no data is provided. */
	if (!player || !info) {
		return false;
	}
	const updatePlayerTracker = new SimpleErrorTracker('Update Player Call', 100, {
		id: player_id,
		position: player_name,
		renderGroup: ERROR_TRACKER_RENDER_GROUP.general,
	});

	/* Early exit if ad is playing. Can't switch on ad playback. */
	if (player?.ima?.controller?.sdkImpl?.isAdPlaying()) {
		updatePlayerTracker.sendError(
			{
				name: 'Update Player Error',
				message: "Can't switch on ad playback",
			},
			1,
		);
		return false;
	}
	/* Update player autoplay. */
	player.autoplay(autoplay);
	/* Don't update player if no new info is passed. */
	const info_change = player.safeupdate().update({
		info,
	});
	if (!info_change) {
		updatePlayerTracker.sendError(
			{
				name: 'Update Player Error',
				message: 'No new info is passed',
			},
			1,
		);
		return false;
	}
	if (show_title_overlay)
		player.overlay({
			content: overrided_title || info.title,
			align: 'top-left',
			overlays: [
				{
					start: 'overlayActive',
					end: 'overlayInactive',
				},
			],
		});
	const is_playlist = Array.isArray(info);
	if (is_playlist) {
		const playlist = info.map((info) => generate_videojs_info(info, player));
		player.playlist().update(playlist, _udl);
	} else {
		const videojs_info = generate_videojs_info(info, player);
		player.poster(info.poster);

		/* Initialize the ComScore plugin for the updated video */
		initialize_comscore(player, info);

		info.is_manually_triggered = load_info.is_manually_triggered;
		info.recommendation_index = load_info.recommendation_index;
		info.load_method = load_info.load_method;

		const event = new CustomEvent('playerUpdate', { detail: info });
		const shapedEvent = shapeOVPEvent('playerUpdate', player.id(), info);
		player.el_.dispatchEvent(event);
		window.dispatchEvent(shapedEvent);
		player.src(videojs_info.sources);
		player.play();
	}
	updatePlayerTracker.sendSuccess();
	return true;
}

/* Player initialization routine with VideoJS. */
export function initialize_player(
	elem,
	info,
	player_id,
	autoplay = false,
	ads = {},
	_udl = {},
	recommendations = true,
	show_title_overlay = false,
	overrided_title,
	is_manually_triggered = false,
	player_name = '',
	on_error,
	initializeTracker,
	nielsen,
	share_embed,
	min_player_enabled,
	volume_level,
) {

	const is_livestream = info?.videoType === '2';
	const is_playlist = Array.isArray(info);
	const is_ads_enabled = !ads?.disable_ads && ads?.ad_tag_url && canRunAds();
	const is_firefox = detectBrowser() === 'Firefox';

	// Logging adBlocker
	if (!is_ads_enabled) {
		const adBlockerTracker = new SimpleErrorTracker('Ads will not be initialized', 100, {
			id: player_id,
			position: player_name,
			renderGroup: ERROR_TRACKER_RENDER_GROUP.videoInit,
		});
		adBlockerTracker.sendError(
			{
				name: 'is_ads_enabled flag set to false',
				message: {
					disable_ads: ads?.disable_ads,
					ad_tag_url: ads?.ad_tag_url,
					isAdBlockerActive: window.isAdBlockerActive,
					is_playlist,
					is_livestream,
				},
			},
			1,
		);
	}

	/* Initialize player on element. */
	const player = videojs(
		elem,
		{
			id: player_id,
			aspectRatio: '16:9',
			fill: true,
			responsive: true,
			controls: true,
			muted: volume_level === 0,
			preload: 'auto',
			autoplay: isMobile() ? autoplay : undefined,
			html5: {
				vhs: {
					// For more detail about overrideNative
					// https://github.com/videojs/http-streaming#overridenative
					overrideNative: !videojs.browser.IS_SAFARI,
				},
				nativeAudioTracks: false,
				nativeVideoTracks: false,
			},
			useCueTags: true,
			liveui: true,
			controlBar: {
				playToggle: true,
				volumePanel: true,
				progressControl: true,
				currentTimeDisplay: true,
				timeDivider: true,
				remainingTimeDisplay: false,
				fullscreenToggle: true,
				pictureInPictureToggle: false,
			},
			title: info?.title || info?.[info.length - 1]?.title || '',
			...(!is_playlist && !info.DRM ? generate_videojs_info(info, null) : {}),
		}
	);

	if (info.DRM) {
		player.poster(info.poster);
		initialize_drm(player, info);
	}

	// Attach share_embed code
	player.shareEmbed = share_embed;

	// Make it easier to access whether player is livestream or not from other files.
	player.isLivestream = is_livestream;

	// Make it easier to identify if the preroll already ended.
	player.isPrerollEnded = false;

	// Creates initTime to track time spent watching
	player.initTime = null;

	// Creates id3metadata attribute for nielsen
	player.id3metadata = null;

	const addSpinnerAndPlay = () => {
		if (autoplay && !isMobile()) {
			player.addClass('vjs-waiting-ads');
			// Best practice for autoplay, instead of adding it into videojs init config.
			if (volume_level === 0) {
				player.autoplay('muted');
			} else {
				player.volume(volume_level/100);
				player.autoplay(true);
			}
			// player.play() returns a promise, so we can catch errors by adding a catch block.
			const playPromise = player.play();
			if (playPromise !== undefined) {
				playPromise.catch((error) => {
					const handleErrorTracking = (err) => {
						// Keep track of player.isOnErrorCalled since more than 1 error
						// Handler can get triggered for an error scenario.
						if (!player.isOnErrorCalled) {
							player.isOnErrorCalled = true;
							console.warning('on_error was called in player.play() catch handler');
							initializeTracker.sendError(
								{
									name: 'Initialize Player Error',
									message: err,
								},
								1,
							);
							on_error(err);
						}
					}
					// Try to play the video once again muted in case of autoplay with sound
					// Without any user interraction failure.
					if (autoplay) {
						player.muted(true);
						player.play().catch((err) => {
							handleErrorTracking(err)
						})
					} else {
						handleErrorTracking(error)
					}
				});
			}
		}
	};

	if (is_livestream) {
		player.autoplay(autoplay);
		player.on('ready', () => {
			addCustomTagParsers(player, show_title_overlay, overrided_title, info.title);
			initializeTracker.sendSuccess();
		});

		player.bufferingTimeout = null;
		player.on('waiting', () => {
		  if (player.bufferingTimeout) {
		  	clearTimeout(player.bufferingTimeout);
		  }
		  player.bufferingTimeout = setTimeout(() => {
			player.src(player.currentSrc());
			player.play();
		  }, 10000);
		});

		player.on('playing', () => {
		  if (player.bufferingTimeout) {
			clearTimeout(player.bufferingTimeout);
		  }
		});

	} else if (!is_firefox)
		player.ready(() => {
			addSpinnerAndPlay();
			initializeTracker.sendSuccess();
		});
	else {
		// Call player.play on loadeddata for browsers that does not support player.ready function
		// aka Firefox
		player.one('loadeddata', () => {
			addSpinnerAndPlay();
			initializeTracker.sendSuccess();
		});
	}

	player.one('play', () => {
		addOneTimeEvents(player, ads, player_id, player_name);
		if (autoplay) {
			player.volume(volume_level/100);
			player.trigger('volumechange');
		} else {
			player.addClass('vjs-waiting-ads');
		}

		if (is_ads_enabled) {
			try {
				player.ima.addEventListener(window?.google?.ima.AdEvent.Type.STARTED, () => {
					player.removeClass('vjs-waiting-ads');
				});

				// Helpful for livestream
				player.one('timeupdate', () => {
					player.removeClass('vjs-waiting-ads');
				});
			} catch (e) {
				player.removeClass('vjs-waiting-ads');
			}
		} else player.removeClass('vjs-waiting-ads');
	});

	videoStartDispatcher(player, ads, player_id, player_name);

	// Just to be safe for ad blockers or ad timeout.
	player.one('timeupdate', () => {
		player.removeClass('vjs-waiting-ads');
	});

	/* Create analytics payload for the corresponding OVP instance. */
	const analyticsPayload = {
		adLoadType: 'dynamic',
		errorMessage: '',
		floatCount: 0,
		floatStatus: false,
		fullScreen: false,
		livestream: is_livestream,
		muteStatus: volume_level === 0,
		playerName: player_name,
	};
	// Defining videoInitType based on is_manually_triggered
	analyticsPayload.videoInitType = is_manually_triggered ? 'manual' : 'auto';
	// Defining loadMethod and recommendationIndex for initial video
	analyticsPayload.loadMethod = 'initial';
	analyticsPayload.recommendationIndex = 0;
	/* Set inline flag to true for iOS devices. */
	player.playsinline(true);
	/* Initialize playlists if provided. */
	if (is_playlist) {
		/* Run playlist related tasks once playlist the plugin is loaded. */
		// Set analytics payload for the first video, for other videos of playlist
		// resetAnalyticsPayloadDict is used in an event listener for playlistNewItem event
		setAnalyticsPayloadUsingAnvatoResponse(analyticsPayload, info[0]);
		const playlist = info.map((info) => ({
			...info,
			...generate_videojs_info(info, player),
		}));
		player.playlist(playlist, _udl, show_title_overlay, overrided_title);
		/* Initialize recommendation plugin. */
		player.firstRecommendationID = info?.[info.length - 1]?.recommendations?.[0]?.mcpid;
		if (recommendations && !is_livestream)
			player.recommendation({
				recommendations: info?.[info.length - 1]?.recommendations,
				isPlaylist: is_playlist,
				show_title_overlay,
				overrided_title,
				player_id,
				player_name,
				isCustomRecommendationsSet: Array.isArray(recommendations),
				min_player_enabled,
			});

		const playlistInterface = player.addChild('playlistInterface', {
			playlist,
		});

		playlistInterface.createPlaylistItems();
		player.controlBar.addChild('PlaylistInterfaceButton', {}, 4);
		// We need to add a class to playlist players so we can control spacing in the control bar.
		player.addClass('player-with-playlist');
	} else {
		setAnalyticsPayloadUsingAnvatoResponse(analyticsPayload, info);
		analyticsPayload.videoUrl = info.url;
		analyticsPayload.playlistIndex = 0;
		analyticsPayload.playlistSize = 0;
		analyticsPayload.playerId = player.id();
		/* Initialize recommendation plugin. */
		player.firstRecommendationID = info.recommendations?.[0]?.mcpid;
		if (recommendations && !is_livestream)
			player.recommendation({
				recommendations: info.recommendations,
				isPlaylist: is_playlist,
				show_title_overlay,
				overrided_title,
				player_id,
				player_name,
				isCustomRecommendationsSet: Array.isArray(recommendations),
				min_player_enabled,
			});

		// Overlay configuration for single video
		// Overlay for playlists handled in Playlist.js file
		if (show_title_overlay)
			player.overlay({
				content: overrided_title || info.title,
				align: 'top-left',
				overlays: [
					{
						start: 'overlayActive',
						end: 'overlayInactive',
					},
				],
			});
	}
	/* The methods bellow only work with one object. */
	const videoInfo = is_playlist ? info[0] : info;

	analyticsPayloadDict[player.id()] = analyticsPayload;
	initialize_ui(player, videoInfo);
	/* Internal state for integration. */
	/* The player can accept the playlist but the ad plugins dont. */
	player.safeupdate({
		info,
		ads: null,
	});
	let nSdkInstance;
	/* Initialize ads if allowed. */
	if (is_ads_enabled) {
		// initialize nielsen
		if (Object.keys(nielsen)?.length) nSdkInstance = initialize_nielsen(player, nielsen, videoInfo);
		
		const params = { content_len: videoInfo.videoDuration || null };
		const additional_params = {};
		if (ads?.isFreewheel && Array.isArray(info)) {
			additional_params.caid = videoInfo.id;
		}
		const updatedAds = updateAdRequest(ads, params, additional_params, volume_level === 0);
		initialize_ads(player, updatedAds, on_error, player_id, player_name, nSdkInstance);
		/* Initialize the ComScore plugin. */
		initialize_comscore(player, videoInfo);
	}
	player.isFloating = false;
	/* Return initalized player. */
	return player;
}
