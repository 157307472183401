/* eslint-disable no-unused-vars */
/* Import videojs. */
import videojs from 'video.js';

import Cookies from 'js-cookie';

const COMSCORE_PUBLISHER_ID = '6036439';
const STAR_NULL = '*null';

/**
 * "The JavaScript logic that GTM uses to set the consent flag cs_ucfr based
 * on the U.S. Privacy String cookie works like this" - Brian C. (OVP-180)
 *
 * We may in the future source the privacy string and the CCPA activation from
 * UDL but, right now that mechanism is not reliable.
 */
function getComScoreConsent() {
	let cfr = null;
	const usp = Cookies.get('usprivacy');

	if (usp !== undefined) {
		if (usp.match(/1NNN|1---/)) {
			cfr = '';
		} else if (usp.match(/1NYN|1YYN/)) {
			cfr = 0;
		} else if (usp === '1YNN') {
			cfr = 1;
		}
	}

	if (usp === undefined && window.__inScopeForCCPA === true) {
		cfr = '';
	}

	return cfr;
}

export default function initialize_comscore(player, info) {
	/* Enables and Configures the ComScore Plugin */
	try {
		/* The ComScore plugin expected videojs to be in global scope. Maybe
		 * they need to get with the program and follow the
		 * (plugin guidelines)[https://docs.videojs.com/tutorial-plugins.html]
		 * where the value of this would provide access to the player. So the
		 * following will hoist videojs into global scope so the ComScore
		 * plugin can hook up.
		 */
		global.videojs = videojs;

		/* Purposefully doing the require here which triggers the ComScore to
		 * shim the player. Remember require is cached.
		 */
		require('../comscore/videojs-plugin-v2');
		const comScoreConsent = getComScoreConsent();
		const csStr = comScoreConsent !== '' ? `"${comScoreConsent}"` : undefined;
		/* Ok now we can configure the publisher id and be on our way... */
		const comscoreConfig = {
			publisherId: COMSCORE_PUBLISHER_ID,
			labelMapping: comScoreConsent === null ? '' : `cs_ucfr=${csStr}`,
			...(player?.isLivestream && { // first-party IDs for Comscore UDM 2.0 
				persistentLabels: `cs_fpid=${window.NXSTdata?.identity?.nexstarId}, cs_fpit=c, cs_fpdm=${STAR_NULL}, cs_fpdt=${STAR_NULL}`,
			})
		};
		player.comscore(comscoreConfig);
		console.info(`ComScore plugin activated for player id:[${player.id()}]!`);

		let durationCode = 'vc11';
		const durationInMin =  Math.floor(parseInt(info.videoDuration, 10) / 60);

		if (player?.isLivestream) {
			durationCode = 'vc13';
		}
		else if (durationInMin > 9) {
			durationCode = 'vc12';
		}
		/* tack in metadata */
		const contentMetadata = {
			ns_st_ci: info?.id,
			ns_st_pu: 'Nexstar', // publisher name; always set to "Nexstar"
			ns_st_st: window.NXSTdata?.site?.callSign?.toLocaleLowerCase() || STAR_NULL, // set to station call letters, all lowercase; e.g., "ktvx" or "wavy"
			ns_st_ct: durationCode,

			ns_st_pr: info?.programName, // program title – but use if one is provided by Anvato
			ns_st_ep: info?.title || STAR_NULL, // episode name

			cs_fpid: window.NXSTdata?.identity?.nexstarId, // 1P identifier
			cs_fpit: 'c', // 1P identifier type

			/* not readily available */
			cs_fpdm: STAR_NULL, // 1P demographics data
			cs_fpdt: STAR_NULL, // Type of 1P demographics data in cs_fpdm
			ns_st_sn: STAR_NULL, // episode season number
			ns_st_en: STAR_NULL, // episode number
			ns_st_pl: STAR_NULL, // playlist title

			// eslint-disable-next-line max-len
			ns_st_ge: 'News',

			c2: COMSCORE_PUBLISHER_ID,
			c3: window.NXSTdata?.site?.domainName || STAR_NULL, // set to the site domain; e.g., "abc4.com" or "fox2now.com"
			c4: player?.isLivestream ? 'livestream' : 'vod', // set to the video type: VOD or live
			c6: STAR_NULL,
		};
		console.debug('contentMetadata', contentMetadata);

		// loop as per VideoJS Implementation Guide
		const elem = player.el();
		// eslint-disable-next-line no-restricted-syntax
		for (const labelName in contentMetadata) {
			// eslint-disable-next-line no-prototype-builtins
			if (contentMetadata.hasOwnProperty(labelName)) {
				// Assume video(elem) is a reference to the HTML5 video element used for VideoJS.
				elem.dataset[labelName] = contentMetadata[labelName];
			}
		}
	} catch (e) {
		console.error(`Failed to activate ComScore plugin for player id:[${player.id()}]!`, e);
	}
}
